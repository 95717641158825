<template>
  <div class="container">
    <div class="content">
      <!-- 左侧信息 -->
      <div class="left">
        <!-- 数据总览 -->
        <div class="card-item">
          <div class="item-head"><label>数据总览</label></div>
          <ul class="inline-ul">
            <li>
              <h4>累计线索</h4>
              <h2>{{ total.clue.current.uv }}</h2>
              <h5>今日新增{{ total.clue.contrast.uv }}</h5>
            </li>
            <li>
              <h4>累计客户</h4>
              <h2>{{ total.clue.current.uv }}</h2>
              <h5>今日新增{{ total.clue.contrast.uv }}</h5>
            </li>
            <li>
              <h4>累计线索池</h4>
              <h2>{{ total.clue.current.uv }}</h2>
              <h5>今日新增{{ total.clue.contrast.uv }}</h5>
            </li>
            <li>
              <h4>累计访问量</h4>
              <h2>{{ total.visit.current.pv }}</h2>
              <h5>今日新增{{ total.visit.contrast.pv }}</h5>
            </li>
          </ul>
        </div>

        <!-- 名片核心指标浏览 -->
        <div class="card-item">
          <div class="item-head"><label>名片核心指标浏览(7日内)</label></div>
          <ul class="block-ul">
            <!-- 被转发 -->
            <li>
              <h3 class="text-bold">
                被转发(次数)
                <el-tooltip effect="dark" placement="right"
                  content="所选时间范围，所有开通AI雷达员工被转发名片的总次数。">
                  <i class="text-tips zgcms">&#xe661;</i>
                </el-tooltip>
                <br />{{total.share.contrast.pv}}
              </h3>
              <h5 class="text-right">累计转发<br>{{total.share.current.pv}}</h5>
            </li>
            <li>
              <line-chart :lineId="'share'" :width="'100%'" :height="'300px'"
                :optRenderer="'canvas'"
                :option="total.share.lineData">
              </line-chart>
            </li>

            <!-- 交换电话 -->
            <li>
              <h3 class="text-bold">
                交换电话(人数)
                <el-tooltip effect="dark" placement="right"
                  content="所选时间范围，所有开通AI雷达员工被交换电话的总人数。">
                  <i class="text-tips zgcms">&#xe661;</i>
                </el-tooltip>
                <br />{{total.exchangephone.contrast.pv}}
              </h3>
              <h5 class="text-right">累计交换<br>{{total.exchangephone.current.pv}}</h5>
            </li>
            <li>
              <line-chart :lineId="'exchangephone'" :width="'100%'" :height="'300px'"
                :optRenderer="'canvas'"
                :option="total.exchangephone.lineData">
              </line-chart>
            </li>

            <!-- 被点赞 -->
            <li>
              <h3 class="text-bold">
                被点赞(次数)
                <el-tooltip effect="dark" placement="right"
                  content="所选时间范围，所有开通AI雷达员工被访客点赞的总次数。">
                  <i class="text-tips zgcms">&#xe661;</i>
                </el-tooltip>
                <br />{{total.raiseup.contrast.pv}}
              </h3>
              <h5 class="text-right">累计点赞<br>{{total.raiseup.current.pv}}</h5>
            </li>
            <li>
              <line-chart :lineId="'raiseup'" :width="'100%'" :height="'300px'"
                :optRenderer="'canvas'"
                :option="total.raiseup.lineData">
              </line-chart>
            </li>
          </ul>
        </div>
      </div>

      <!-- 右侧信息 -->
      <div class="right">
        <!-- 套餐信息 -->
        <div class="card-item">
          <div class="item-head">
            <label>套餐信息</label>
            <span>套餐详情<i class="zgcms">&#xe912;</i></span>
          </div>
          <ul class="block-ul">
            <li>
              <label>开通名片</label>
              <span>{{ company.memberNumber }}/{{ company.memberLimit }}</span>
            </li>
            <li>
              <label>已用天数</label>
              <span>{{$dayjs().diff($dayjs(company.createdAt), "day")}}</span>
            </li>
            <li>
              <label>有效期至</label>
              <span>{{ company.expireAt | dateFormat("YYYY-MM-DD") }}</span>
            </li>
          </ul>
        </div>

        <!-- 流量使用 -->
        <div class="card-item">
          <div class="item-head">
            <label>流量使用</label>
            <span>流量说明<i class="zgcms">&#xe912;</i></span>
          </div>
          <ul class="block-ul">
            <li><label>本周已使用流量</label><span>0B</span></li>
            <li><label>上周已使用流量</label><span>0B</span></li>
            <li><label>累计使用流量</label><span>0B/1TB</span></li>
          </ul>
        </div>

        <!-- 公告 -->
        <div class="card-item">
          <div class="item-head">
            <label>公告</label>
            <span @click="$winRedirectPage(0, '#/qllcompany/replace')">更多<i class="zgcms">&#xe912;</i></span>
          </div>
          <ul class="block-ul block-notice">
            <li v-for="item in versionList" :key="item.id"
              @click="noticeContent = item.content; viewVisible = true">
              <label>公告：{{item.intro}}</label>
              <span>{{item.created_at | dateFormat('MM-DD')}}</span>
            </li>
            <li v-if="versionList.length < 1"><label>暂无公告</label></li>
          </ul>
        </div>
        <el-dialog width="450px" title="版本内容"
          :visible.sync="viewVisible"
          :close-on-click-modal="false">
          <div v-html="noticeContent"></div>
        </el-dialog>

        <!-- 联系我们 -->
        <div class="card-item">
          <div class="item-head">
            <label>联系我们</label>
          </div>
          <ul class="block-ul">
            <li><label>客服热线</label><span>400-1177-595</span></li>
          </ul>
        </div>

        <!-- 关注商机圈子公众号 -->
        <div class="card-item">
          <div class="zg-info">
            <h3>关注商机圈子公众号</h3>
            <p>了解更多客户案例、使用技巧、最新功能介绍</p>
          </div>
          <img class="zg-qrcode" src="https://image.qll-times.com/2021/06/c1a2edf4cb3b435ba3331e59360ad655.jpg"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "@/websites/cms/components/charts/Line";
export default {
  components: { LineChart },
  data() {
    return {
      company: {}, // 公司信息
      total: {
        clue: {current: { pv: 0, uv: 0 }, contrast: { pv: 0, uv: 0 }, lineData: {}},
        visit: {current: { pv: 0, uv: 0 }, contrast: { pv: 0, uv: 0 }, lineData: {}},
        share: {current: { pv: 0, uv: 0 }, contrast: { pv: 0, uv: 0 }, lineData: {}},
        exchangephone: {current: { pv: 0, uv: 0 }, contrast: { pv: 0, uv: 0 }, lineData: {}},
        raiseup: {current: { pv: 0, uv: 0 }, contrast: { pv: 0, uv: 0 }, lineData: {}},
      },
      versionList: [],
      viewVisible: false,
      noticeContent: ''
    };
  },
  async created() {
    await this.getMyCompany();
    this.getClue();
    this.getVisit();
    this.getCardVisitExchange();
    this.getLineData("share", "被转发次数");
    this.getLineData("exchangephone", "被交换电话次数");
    this.getLineData("raiseup", "被点赞次数");
    this.getVersionList()
  },
  methods: {
    // 获取企业信息
    async getMyCompany() {
      let res = await this.$ZGManager.getMyCompany();
      if (res.status != 200) return;
      this.company = res.data;
    },

    // 获取企业线索数
    async getClue() {
      let res = await this.$ZGManager.statRanges(
        {
          cid: this.company.id,
          ranges: [{ key: "total" }, { key: "yesterday", from: "now-0d/d" }],
        },
        "trace",
        "cid"
      );
      if (res.status != 200) return;
      if (res.data.total.length > 0)
        this.total.clue.current = res.data.total[0];
      if (res.data.yesterday.length > 0)
        this.total.clue.contrast = res.data.yesterday[0];
    },

    // 获取企业访问数
    async getVisit() {
      let res = await this.$ZGManager.statRanges({
        cid: this.company.id,
        evt: "visit",
        ranges: [{ key: "total" }, { key: "yesterday", from: "now-0d/d" }],
      });
      if (res.status != 200) return;
      if (res.data.total.length > 0)
        this.total.visit.current = res.data.total[0];
      if (res.data.yesterday.length > 0)
        this.total.visit.contrast = res.data.yesterday[0];
    },

    // 获取名片被转发、交换电话、点赞的7日数据和累计数据
    async getCardVisitExchange(){
      let evt = ["share", "exchangephone", "raiseup"];
      let res = await this.$ZGManager.statRanges({
        cid: this.company.id,
        rt: "card",
        evt: evt,
        ranges: [
          {key: "current"},
          {key: "contrast", from: "now-7d/d"}
        ]
      });
      let current = {}, contrast = {};
      res.data.current.map(e => current[e.key] = {pv: e.pv, uv: e.uv});
      res.data.contrast.map(e => contrast[e.key] = {pv: e.pv, uv: e.uv});
      evt.map((e) => {
        if(current.hasOwnProperty(e)) this.total[e].current = current[e];
        if(contrast.hasOwnProperty(e)) this.total[e].contrast = contrast[e];
      });
    },

    // 获取名片被转发、交换电话、点赞的折线图数据
    async getLineData(key, tips){
      let res = await this.$ZGManager.statHistograms({
        cid: this.company.id,
        rt: "card",
        evt: key,
        ranges: [{key: "week", from: "now-7d/d"}]
      }, "d", "trace");
      let option = {
        title: {show: true, subtext: tips, left: "center"},
        xAxis: {type: "category", boundaryGap: false, data: []},
        yAxis: {type: "value"},
        series: [{data: [], type: "line" }],
        color: '#6E87BD',
        tooltip: {trigger: 'axis'},
      }
      for(let i = 6; i >= 0; i--){
        option.xAxis.data.push(this.$dayjs().subtract(i, 'day').format('YYYY-MM-DD'));
        option.series[0].data.push(0);
      }
      if(res.status == 200 && res.data.histograms.length > 0){
        option.xAxis.data.map((item, index) => {
          res.data.histograms.map((e) => {
            if(item == e.key) option.series[0].data[index] = e.pv
          });
        })
      }
      this.total[key].lineData = option;
    },

    // 获取当前的更新列表
    async getVersionList(){
      let res = await this.$ZGManager.versionInfo(this.params);
      if (res.status !== 200) return;
      this.versionList = res.data.list;
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  .left {
    width: 840px;
    float: left;
  }
  .right {
    width: 300px;
    float: left;
  }
}
.card-item {
  width: calc(100% - 48px);
  padding: 16px 24px 14px 24px;
  margin-bottom: 16px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  .item-head {
    width: 100%;
    height: 36px;
    border-bottom: 1px solid #e4e8eb;
    display: flex;
    justify-content: space-between;
    label {
      color: rgba(0, 0, 0, 0.8);
      font-size: 18px;
    }
    span {
      cursor: pointer;
      color: #578bcf;
      font-size: 14px;
      i {
        font-size: 12px;
      }
    }
  }
  .block-ul {
    width: 100%;
    margin-bottom: 0;
    padding-top: 8px;
    li {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 4px 0;
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
    }
    .text-bold {
      color: #000;
      font-size: 14px;
      font-weight: 500;
      padding-left: 42px;
    }
    .text-right {
      text-align: right;
      padding-right: 42px;
    }
    .text-tips {
      color: #999;
      cursor: pointer;
    }
  }
  .block-notice {
    li {
      padding: 10px 0;
      border-bottom: 1px solid #e4e8eb;
      &:nth-last-child(1) {
        border: transparent;
      }
    }
    label {
      cursor: pointer;
      width: 76.3%;
    }
  }
  .inline-ul {
    margin-top: 32px;
    display: flex;
    li {
      width: 25%;
      text-align: center;
      h4 {
        color: rgba(#000, 0.8);
        font-size: 14px;
        padding-bottom: 10px;
      }
      h2 {
        font-size: 32px;
        font-weight: 200;
      }
      h5 {
        color: #f5a623;
        font-size: 14px;
        line-height: 1.5em;
      }
    }
  }
  .zg-info {
    width: 65%;
    float: left;
    p {
      margin-top: 10px;
      color: #bbb;
      font-size: 12px;
    }
  }
  .zg-qrcode {
    width: 30%;
    float: right;
  }
}
</style>